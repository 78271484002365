import React from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Default } from "../../helpers/Breakpoints";
import { Mobile } from "../../helpers/Breakpoints";

// import mobileImg from "../../../assets/rondancing.gif";
// import mobileImg from "../../../assets/mobBack.JPG";
import scrollDown from "../../../assets/Scroll-Down-icon-Home.gif";

export default function HeroSection() {
  const location = useLocation();
  const history = useHistory();

  const redirectToParties = () => {
    const urlParams = new URLSearchParams(location.search);
    const city = urlParams.get("city");
    if (!city) {
      console.log("city not found");
      return;
    }
    urlParams.set("page", "1");
    urlParams.set("filter", "all");
    history.push({ search: urlParams.toString(), pathname: "/parties" });
  };

  return (
    <section className="wholegbg_black mainHero_cont">
      <Default>
        <video
          autoPlay
          muted
          loop
          src="https://mum-objectstore.e2enetworks.net/playace-files/web-videos/1722088661833809.mp4"
          playsInline
        ></video>
      </Default>

      <Mobile>
        <video
          autoPlay
          muted
          loop
          src="https://mum-objectstore.e2enetworks.net/playace-files/web-videos/1722088661833809.mp4"
          playsInline
        ></video>
      </Mobile>

      <div className="container">
        <div className="heroText">
          <h1>Attend Exciting House Parties with Verified Strangers! </h1>
          <button
            className="btn btn-reg btn-primary customizeBtn"
            onClick={redirectToParties}
          >
            <div className="iconSpace">
              Explore Now
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icon icon-tabler icons-tabler-outline icon-tabler-hand-love-you"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M11 11.5v-1a1.5 1.5 0 0 1 3 0v1.5" />
                <path d="M17 12v-6.5a1.5 1.5 0 0 1 3 0v10.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7a69.74 69.74 0 0 1 -.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47" />
                <path d="M14 10.5a1.5 1.5 0 0 1 3 0v1.5" />
                <path d="M8 13v-8.5a1.5 1.5 0 0 1 3 0v7.5" />
              </svg>
            </div>
          </button>

          <Mobile>
            <img src={scrollDown} alt="" className="scrollDownArrow" />
          </Mobile>
        </div>
      </div>

      <div className="topGradient"></div>
      <div className="bottonGradient"></div>
    </section>
  );
}
