import React, { useRef } from "react";
import styles from "./Dashboard.module.scss";
import { Card } from "../Profile";
import { useDispatch, useSelector } from "react-redux";
import { Mobile, Default } from "../../../helpers/Breakpoints";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Badge, Button, Container, Form, Modal } from "react-bootstrap";
import {
  cancelParty,
  fetchallGuestEventsbyPhone,
  fetchProfile,
  getInterests,
  getProfileEvents,
  getRefundPercentage,
  updateAboutSection,
  updateInterests,
  updateProfileImage,
  fetchReviewsByHost,
  getPartyStatus,
} from "../../../../api/requests";
import { RenderUserObj } from "../../../helpers";
import { getFormattedDate } from "../../../../utils/common";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { startOnFidoVerification } from "../../../modules/verification/onfido";
import {
  updateGuestCount,
  updatePromoCode,
} from "../../../../actions/guestBookActions";
import { handleFileUploadS3 } from "../../../../utils/fileUploader";

import mapIcon from "../../../../assets/image 111.png";

import infoPositive from "../../../../assets/image 53.png";
import infoNegative from "../../../../assets/image 54.png";
import { formatTime, getLoader, showStarRating } from "../../../../utils/utils";
import noParty from "../../../../assets/noparty.png";

const apiRequest = {
  page: 1,
  limit: 4,
};

function Dashboard() {
  const userObj = RenderUserObj(useSelector((state) => state.auth.data));
  const authState = useSelector((state) => state.auth);

  const isAuthenticated = useSelector((state) => state.auth);
  const history = useHistory();
  const [userData, setUserData] = useState([]);

  const [upcomingParties, setUpcomingParties] = useState([]);
  const [userParties, setUserParties] = useState({
    upcomingEvents: [],
    pastEvents: []
  });

  const [loading, setLoader] = useState([]);
  const [partiesLoading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    occupation: "",
    language: "",
    location: "",
    uselessSkill: "",
    favoriteMovie: "",
    favoriteTvShow: "",
    funFact: "",
  });
  const [displayCountIncoming, setDisplayCountIncoming] = useState(4);
  const [displayCountPast, setDisplayCountPast] = useState(4);
  

  const [isOpenSlide, setIsOpenSlide] = useState(false);
  const [selectedParty, setSelectedParty] = useState(null);

  const [interestsList, setInterests] = useState([]);
  const [profileData, setProfileData] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [ticketModal, setTicketModal] = useState(false);

  const [refundResponse, setRefundResponse] = useState(false);
  const [hostReviewData, setHostReviewData] = useState({
    reviews: [],
    totalCount: 0,
    totalPages: 0,
    ratingAverage: 0,
  });
  const [showMore, setShowMore] = useState(true);

  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  const handleInterestClick = (interest) => {
    setSelectedInterests((prevInterests) => {
      if (prevInterests.includes(interest)) {
        return prevInterests.filter((item) => item !== interest);
      } else {
        return [...prevInterests, interest];
      }
    });
  };

  const handleSave = async () => {
    const response = await updateInterests(selectedInterests);

    setShowModal(false);
  };

  const handleFileUpload = async (event) => {
    try {
      let s3url = new Promise((resolve, reject) => {
        let file = handleFileUploadS3(event.target.files, "profiles");
        resolve(file);
      });
      s3url.then(async (item) => {
        try {
          let updateProfile = await updateProfileImage(
            userObj.token,
            userObj.userId,
            item
          );
          // window.location.reload()
          getProfileData();
        } catch (error) {
          console.error("s3url", item);
        }
      });
    } catch (error) {
      // Handle any errors that occur during the file upload
      console.error("Error uploading file:", error);
    }
  };

  const redirectToParties = () => {
    history.push({ pathname: "/parties" });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const toggleEdit = async () => {
    setEdit(!edit);
    const response = await updateAboutSection(formData);
    console.log("response", response);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    } else {
      setUserData(userObj);
      callApis();
    }
  }, []);

  const callApis = async () => {
    getProfileData();
    callInterests();
    callProfileEvents();
    apiRequest.page = 1;
    apiRequest.limit = 4;
    callReviewApi();
  };

  const getProfileData = async () => {
    try {
      setLoader(true);
      const response = await fetchProfile();
      if (response.status === "success") {
        const allFieldsEmpty = Object.values(response?.data?.about).every(
          (value) => value === ""
        );

        if (allFieldsEmpty) {
          setEdit(true);
        }

        setFormData(response?.data?.about);
        setSelectedInterests(response?.data?.interest?.map((item) => item.id));
        setProfileData(response?.data);
      }
    } catch (e) {
    } finally {
      setLoader(false);
    }
  };

  const callProfileEvents = async () => {
    setLoading(true)
    const eventResponse = await fetchallGuestEventsbyPhone(
      userObj?.data?.userId
    );

    if (eventResponse.status === "success") {
      setUserParties(eventResponse.data);
    }
    setLoading(false)
  };

  const callInterests = async () => {
    const response = await getInterests();

    if (response.status === "success") {
      setInterests(response.data);
    }
  };

  const callReviewApi = async () => {
    try {
      const response = await fetchReviewsByHost(
        userObj.userId,
        apiRequest.page,
        apiRequest.limit
      );
      if (hostReviewData) {
        setHostReviewData((prevState) => ({
          reviews: [...prevState.reviews, ...response?.data?.reviews],
          totalCount: response.data.totalCount,
          totalPages: response.data.totalPages,
          ratingAverage: response.data.ratingAverage,
        }));
      } else {
        setHostReviewData(response.data);
      }
      if (response?.data?.totalPages > apiRequest.page) {
        setShowMore(true);
      } else {
        setShowMore(false);
      }
    } catch { }
  };

  const loadMoreReview = () => {
    apiRequest.limit = 4;
    apiRequest.page = apiRequest.page + 1;
    callReviewApi();
  };

  const handleCancelEvent = (eventId) => {
    cancelParty({ eventId })
      .then((response) => {
        if (response.status == "success") {
          callProfileEvents();
          handleTicketModal();
        } else {
        }
      })
      .catch((error) => { });
  };
  const handleShowMoreUpcoming = () => {
    // Increase the display count by 4 when "Show More" is clicked
    setDisplayCountIncoming(prevCount => prevCount + 4);
  };
  const handleShowMorePast = () => {
    // Increase the display count by 4 when "Show More" is clicked
    setDisplayCountPast(prevCount => prevCount + 4);
  };
  const showPartyList = (party) => {
    return (
      <div className="wholeCardDetails">
        <div className="covertimeline">
          <Link
            className="link-unstyled"
            to={{
              pathname: `/parties/${party?.eventId}`,
              // state: props.event_sold_out,
              // location: location.pathname,
            }}
            onClick={() => {
              dispatch(updateGuestCount(1));
              dispatch(updatePromoCode(""));
            }}
          >
            <div className="partyBgImg">
              <img src={party?.images?.[0]} alt="" />
            </div>
          </Link>

          <div className="partyTimelineDetailsCont">
            <div>
              <h2 className="titleParty">{party.title}</h2>
              <p className="hostedBy">Hosted By {party?.hostName}</p>
            </div>

            <div className="flexVertif">
              <div className="locationNdate flexCenter">
                <div className="dateCont flexCenter commonpartyInfo">
                  <div className="iconSvg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-calendar-event"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                      <path d="M16 3l0 4"></path>
                      <path d="M8 3l0 4"></path>
                      <path d="M4 11l16 0"></path>
                      <path d="M8 15h2v2h-2z"></path>
                    </svg>
                  </div>
                  <p>
                    {party?.eventDate + " | " + formatTime(party?.startTime)}
                  </p>
                </div>
                <div className="locationCont flexCenter commonpartyInfo">
                  <div className="iconSvg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                      <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                    </svg>
                  </div>
                  <p>{party?.location + " | " + party?.city}</p>
                </div>
              </div>

              {party?.isShowAddress && (
                <button
                  className="see-all-button"
                  onClick={() => {
                    setIsOpenSlide(true);
                    setSelectedParty(party);
                  }}
                >
                  See Address
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-up-right"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M17 7l-10 10" />
                    <path d="M8 7l9 0l0 9" />
                  </svg>
                </button>
              )}
            </div>

            <div className={`twoBtnsCont`}>
              <button
                className={
                  party?.status === "Pending"
                    ? "approvePending"
                    : party?.status === "Rejected"
                      ? "approveRejected"
                      : ""
                }
                onClick={() => {
                  setIsOpenSlide(true);
                  setSelectedParty(party);
                }}
              >
                {party?.status === "Rejected" ? "Disapproved - Refund Status" : party?.status}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-up-right"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M17 7l-10 10" />
                  <path d="M8 7l9 0l0 9" />
                </svg>

              </button>

              {party.canCancel && (
                <button
                  className="cancel"
                  onClick={() => {
                    // handleCancelEvent(party?.eventId);
                    callRefund(party?.eventId);
                    setTicketModal(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-cancel"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M18.364 5.636l-12.728 12.728" />
                  </svg>
                  Cancel Booking
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const GetPartyStatusView = ({ isOpenSlide, setIsOpenSlide, party }) => {
    const boxRef = useRef(null);
    const [partyStatus, setPartyStatus] = useState([]);
    const [isMounted, setIsMounted] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [partyApiCalled, setPartyApiCalled] = useState(true);

    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        triggerClose();
      }
    };

    const triggerClose = () => {
      setIsClosing(true);
      setTimeout(() => {
        setIsOpenSlide(false);
        setIsClosing(false);
        setPartyApiCalled(true);
      }, 350);
    };

    useEffect(() => {
      if (isOpenSlide) {
        let data = {
          eventId: party?.eventId,
          participantId: party?.participantId,
        };

        getPartyStatus(data).then((response) => {
          setPartyStatus(response?.data);
          setPartyApiCalled(false);
        });

        setIsMounted(true);
        document.addEventListener("mousedown", handleClickOutside);

        const handleBackButton = (event) => {
          setIsOpenSlide(false); // Close the sliding div
        };

        window.addEventListener("popstate", handleBackButton);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
          window.removeEventListener("popstate", handleBackButton);
        };
      }
    }, [isOpenSlide]);

    return (
      <>
        {isOpenSlide && (
          <div
            className={`wholeScreenCover ${isMounted && !isClosing ? "opened" : ""
              }`}
          >
            <div className="copounsBoxOuter" ref={boxRef}>
              <div className="innerCoupounsBox">
                <div
                  className="closeIcc"
                  onClick={triggerClose} // Use the triggerClose function on close
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-x"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18 6l-12 12" />
                    <path d="M6 6l12 12" />
                  </svg>
                </div>

                <div className="overFlow">
                  <div className="upperPartyDets">
                    <div className="partyImgBg">
                      <img src={party?.images?.[0]} alt={party?.title} />
                    </div>
                    <h2 className="titleParty">{party?.title}</h2>
                    <p className="hostedBy">Hosted By {party?.hostName}</p>
                  </div>

                  <div className="availableCoupons">
                    <div className="titleAb">
                      <h2>Party Status</h2>
                    </div>

                    {!partyApiCalled ? (
                      <>
                        <div className="partyStatsCont">
                          {partyStatus?.trackings?.map(
                            ({ status, timestamp, statusCode,message }, index) => {
                              const isRevealStatus =
                                status ===
                                "Will be revealed 2hrs before the party";

                              const date =
                                !isRevealStatus && timestamp
                                  ? new Date(timestamp).toLocaleString(
                                    "en-US",
                                    {
                                      dateStyle: "long",
                                      timeStyle: "short",
                                    }
                                  )
                                  : isRevealStatus &&
                                  "Will be revealed 2hrs before the party";

                              let flagClass = "";
                              if (statusCode === 2) {
                                flagClass = "redFlag";
                              } else if (statusCode === 1) {
                                flagClass = "greenFlag";
                              }

                              return (
                                <div className="statInd" key={index}>
                                  <div className={`statusMarker ${flagClass}`}>
                                    {statusCode === 1 && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="icon icon-tabler icons-tabler-outline icon-tabler-check"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M5 12l5 5l10 -10" />
                                      </svg>
                                    )}
                                  </div>
                                  <div className="statusNamenDate">
                                    <p>{status}</p>
                                    <span>{date}</span>
                                    <span>{message}</span>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>

                        {Object.keys(partyStatus).length === 0 && (
                          <div className="addressRevealed mtsmall gradient-border">
                            <div className="innerAddressCont special">
                              <div className="d-flex">
                                <div className="iconAb">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-info-circle"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    />
                                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                    <path d="M12 9h.01" />
                                    <path d="M11 12h1v4h1" />
                                  </svg>
                                </div>
                                <div className="adContxt">
                                  <p>
                                    Status history is available for parties
                                    booked after 14th October 2024. Don't worry—your future
                                    bookings will have full details from request
                                    to refund!
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {partyStatus?.address?.address && (
                          <div className="addressRevealed gradient-border">
                            <div className="innerAddressCont">
                              <div className="d-flex align-items-center">
                                <div className="iconAb">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    />
                                    <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                    <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                                  </svg>
                                </div>
                                <div className="adContxt">
                                  <p>{partyStatus?.address?.address}</p>
                                </div>
                                {partyStatus?.address?.locationUrl && (
                                  <div className="mapDir">
                                    <a
                                      href={partyStatus?.address?.locationUrl}
                                      target="_blank"
                                    >
                                      <span>
                                        <img src={mapIcon} alt="" />
                                      </span>
                                      View on Maps
                                    </a>
                                  </div>
                                )}
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="iconAb">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="icon icon-tabler icons-tabler-outline icon-tabler-user-star"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    />
                                    <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                                    <path d="M6 21v-2a4 4 0 0 1 4 -4h.5" />
                                    <path d="M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z" />
                                  </svg>
                                </div>
                                <div className="adContxt">
                                  <p>{partyStatus?.host?.name}</p>
                                  <span>{partyStatus?.host?.phone}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="loaderCont">
                          <div className="customLoader"></div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const getSuccessTickSVG = (className = "correctIcon") => {
    return (
      <div className={`${className} comIc`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="icon icon-tabler icons-tabler-outline icon-tabler-check"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M5 12l5 5l10 -10" />
        </svg>
      </div>
    );
  };

  const getFailedTickSVG = (className = "wrongIcon") => {
    return (
      <div className={`${className} comIc`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="icon icon-tabler icons-tabler-outline icon-tabler-exclamation-mark"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 19v.01" />
          <path d="M12 15v-10" />
        </svg>
      </div>
    );
  };

  const changeVerificationStatus = () => {
    getProfileData();
  };

  const handleTicketModal = () => {
    setTicketModal(false);
  };

  const getVerificationView = (statusId = 3, isLoading = false) => {
    if (isLoading)
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          size="2x"
          className="text-secondary"
        />
      );

    switch (statusId) {
      case 0:
        return (
          <div class Name="listCommon">
            <div className="ll">
              {getFailedTickSVG()}

              <p>Government ID</p>

              <div className="verifyDocs">
                <button
                  className="outline"
                  onClick={() => {
                    startOnFidoVerification(
                      userObj.data,
                      changeVerificationStatus
                    );
                  }}
                >
                  Verify Now
                </button>
              </div>
            </div>
            <div id="onfido-mount"></div>
          </div>
        );

      case 1:
        return (
          <div class Name="listCommon">
            <div className="ll">
              {getFailedTickSVG()}
              <p>Government ID</p>

              <div className="verifyDocs">Under Review</div>
            </div>
          </div>
        );
      case 2:
        return (
          <div class Name="listCommon">
            <div className="ll">
              {getSuccessTickSVG()}
              <p>Government ID</p>
            </div>
          </div>
        );
      case 3:
        return (
          <div class Name="listCommon">
            <div className="ll">
              {getFailedTickSVG()}
              <p>Government ID</p>

              <div className="verifyDocs">
                <button
                  className="outline"
                  onClick={() => {
                    startOnFidoVerification(
                      userObj.data,
                      changeVerificationStatus
                    );
                  }}
                >
                  Reupload
                </button>
              </div>
            </div>
            <div id="onfido-mount"></div>
          </div>
        );

      default:
        return (
          <button
            id="add-governmentId"
            onClick={() => {
              // setDisableButton(true);
              // startOnFidoVerification(userObj, changeVerificationStatus);
            }}
            className="make-pointer border-0 btn btn-primary fw-bold text-uppercase"
            style={{ fontSize: "0.75rem" }}
          >
            ADD
          </button>
        );
    }
  };

  const callRefund = async (eventId) => {
    try {
      const response = await getRefundPercentage(eventId);
      setRefundResponse(response.data);
    } catch { }
  };

  if (loading) {
    return getLoader();
  }

  return (
    <>
      <GetPartyStatusView
        isOpenSlide={isOpenSlide}
        setIsOpenSlide={setIsOpenSlide}
        party={selectedParty} // Pass the selected party data
      />
      <Modal show={showModal} onHide={handleModalToggle} centered>
        <Modal.Header>
          <Modal.Title>Add / Remove Interests</Modal.Title>
          <div onClick={handleModalToggle}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="icon icon-tabler icons-tabler-outline icon-tabler-x"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M18 6l-12 12" />
              <path d="M6 6l12 12" />
            </svg>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form className="interestForm">
            {interestsList.map((interest, index) => (
              <Form.Check
                type="checkbox"
                key={index}
                id={`interest-${index}`}
                label={interest.interestName}
                checked={selectedInterests.includes(interest.interestId)}
                onChange={() => handleInterestClick(interest.interestId)}
                className="custom-checkbox"
              />
            ))}
          </Form>
          <div className="pillsFooter">
            <p>
              {selectedInterests.length}/{interestsList.length} Selected
            </p>

            <Button variant="primary" onClick={handleSave}>
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* {userData && ( */}

      <Modal
        backdrop="static"
        show={ticketModal}
        onHide={handleTicketModal}
        centered
      >
        <Container className=" text-center py-5 cancelCommon">
          <h5 className="text-uppercase">Are You Sure You Want to Cancel?</h5>

          <p>
            {`Are you sure you want to cancel your booking for "${refundResponse?.eventName}" ? This
            action cannot be undone.`}
          </p>

          <br></br>
          {refundResponse && (
            <div
              className={`bgOverlay ${refundResponse?.refundPercentage < 100 ? "negative" : "positive"
                }`}
            >
              <img
                src={
                  refundResponse?.refundPercentage < 100
                    ? infoNegative
                    : infoPositive
                }
                alt=""
              />
              <p>{`As per the cancellation policy, you are eligible for ${refundResponse?.refundPercentage}% refund`}</p>
            </div>
          )}
          <div className="commonPopUpBtn">
            {refundResponse && (
              <button
                onClick={() => {
                  handleCancelEvent(refundResponse?.eventId);
                }}
                className="btn btn-reg btn-padding text-primary"
              >
                Confirm Cancellation
              </button>
            )}

            <button
              onClick={handleTicketModal}
              className="btn btn-reg btn-primary btn-padding"
            >
              Go Back
            </button>
          </div>
        </Container>
      </Modal>

      <div className="profileGridCommon marless">
        <div className="profileCard">
          <div className="profieInner">
            <div className="userImgBg">
              <div className="relative" onClick={handleClick}>
                <img src={profileData?.profileImage} alt="" />
                <div className="editDp">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-pencil"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" />
                    <path d="M13.5 6.5l4 4" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="userIntro">
              <p>Hi, I am</p>
              <h1>{profileData?.fullName}</h1>
            </div>

            <div className="userInfo">
              <div className="d-flex">
                <div className="col">
                  <h3>{profileData?.partiesAttended}</h3>
                  <p>Parties attended</p>
                </div>
                {profileData?.partiesHosted > 0 && (
                  <div className="col">
                    <h3>{profileData?.partiesHosted}</h3>
                    <p>Parties hosted</p>
                  </div>
                )}
                {parseInt(profileData?.averageRating) != 0 && (
                  <div className="col">
                    <div className="d-flex rateC">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="icon icon-tabler icons-tabler-filled icon-tabler-star"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M8.243 7.34l-6.38 .925l-.113 .023a1 1 0 0 0 -.44 1.684l4.622 4.499l-1.09 6.355l-.013 .11a1 1 0 0 0 1.464 .944l5.706 -3l5.693 3l.1 .046a1 1 0 0 0 1.352 -1.1l-1.091 -6.355l4.624 -4.5l.078 -.085a1 1 0 0 0 -.633 -1.62l-6.38 -.926l-2.852 -5.78a1 1 0 0 0 -1.794 0l-2.853 5.78z"></path>
                      </svg>
                      <h3>
                        {profileData?.averageRating}{" "}
                        <span className="outOf">/5</span>
                      </h3>
                    </div>
                    <p>Avg. rating</p>
                  </div>
                )}
              </div>
            </div>

            <div className="verifiedSection">
              <h2>Verified Information</h2>

              <div className="listOfDocs">
                <div className="listCommon">
                  <div className="ll">
                    {profileData?.isPhoneVerified
                      ? getSuccessTickSVG()
                      : getFailedTickSVG()}
                    <p>Phone number</p>
                  </div>
                </div>

                {getVerificationView(profileData?.statusId)}

                <div className="listCommon">
                  <div className="ll">
                    {profileData?.statusId === 2
                      ? getSuccessTickSVG()
                      : getFailedTickSVG()}
                    <p>Profile Status</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="profileContents">
          <div>
            <div className="userQualitiesCont sameMarbtm">
              <h2 className="profileTitsCom"> About {profileData?.fullName}</h2>
              <Default>
                <button onClick={toggleEdit} className="outline">
                  {edit ? "Save" : "Edit"}
                </button>
              </Default>
              <Mobile>
                {!edit && (
                  <button onClick={toggleEdit} className="outline">
                    {"Edit"}
                  </button>
                )}
              </Mobile>
            </div>
            <div className="row">
              <div className="col">
                {edit ? (
                  <div className="userinputValues">
                    <div className="boxWrapper">
                      <div className="squareIconBoxes">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-briefcase"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                          <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2" />
                          <path d="M12 12l0 .01" />
                          <path d="M3 13a20 20 0 0 0 18 0" />
                        </svg>
                      </div>
                      <div className="relative">
                        <input
                          type="text"
                          name="occupation"
                          value={formData?.occupation}
                          onChange={handleInputChange}
                        />

                        <span className="overLabel">I am</span>
                      </div>
                    </div>
                    <div className="boxWrapper">
                      <div className="squareIconBoxes">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-message"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M8 9h8" />
                          <path d="M8 13h6" />
                          <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
                        </svg>
                      </div>
                      <div className="relative">
                        <input
                          type="text"
                          name="language"
                          value={formData.language}
                          onChange={handleInputChange}
                        />
                        <span className="overLabel">I speak in</span>
                      </div>
                    </div>
                    <div className="boxWrapper">
                      <div className="squareIconBoxes">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                          <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                        </svg>
                      </div>

                      <div className="relative">
                        <input
                          type="text"
                          name="location"
                          value={formData.location}
                          onChange={handleInputChange}
                        />
                        <span className="overLabel">I live in</span>
                      </div>
                    </div>
                    <div className="boxWrapper">
                      <div className="squareIconBoxes">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-mood-smile-beam"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 21a9 9 0 1 1 0 -18a9 9 0 0 1 0 18z" />
                          <path d="M10 10c-.5 -1 -2.5 -1 -3 0" />
                          <path d="M17 10c-.5 -1 -2.5 -1 -3 0" />
                          <path d="M14.5 15a3.5 3.5 0 0 1 -5 0" />
                        </svg>
                      </div>
                      <div className="relative">
                        <span className="overLabel">
                          A fun fact about me is
                        </span>
                        <input
                          type="text"
                          name="funFact"
                          value={formData.funFact}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="userinputValues">
                    <div className="boxWrapper">
                      <div className="squareIconBoxes">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-briefcase"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                          <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2" />
                          <path d="M12 12l0 .01" />
                          <path d="M3 13a20 20 0 0 0 18 0" />
                        </svg>
                      </div>
                      <p>I am {formData?.occupation}</p>
                    </div>
                    <div className="boxWrapper">
                      <div className="squareIconBoxes">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-message"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M8 9h8" />
                          <path d="M8 13h6" />
                          <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
                        </svg>
                      </div>
                      <p>I speak in {formData.language}</p>
                    </div>
                    <div className="boxWrapper">
                      <div className="squareIconBoxes">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                          <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                        </svg>
                      </div>
                      <p>I live in {formData.location}</p>
                    </div>
                    <div className="boxWrapper">
                      <div className="squareIconBoxes">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-mood-smile-beam"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 21a9 9 0 1 1 0 -18a9 9 0 0 1 0 18z" />
                          <path d="M10 10c-.5 -1 -2.5 -1 -3 0" />
                          <path d="M17 10c-.5 -1 -2.5 -1 -3 0" />
                          <path d="M14.5 15a3.5 3.5 0 0 1 -5 0" />
                        </svg>
                      </div>
                      <p>A fun fact about me is {formData.funFact}</p>
                    </div>
                  </div>
                )}
              </div>
              <div className="col">
                {edit ? (
                  <div className="userinputValues">
                    <div className="boxWrapper">
                      <div className="squareIconBoxes">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-wand"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M6 21l15 -15l-3 -3l-15 15l3 3" />
                          <path d="M15 6l3 3" />
                          <path d="M9 3a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" />
                          <path d="M19 13a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" />
                        </svg>
                      </div>
                      <div className="relative">
                        <input
                          type="text"
                          name="uselessSkill"
                          value={formData.uselessSkill}
                          onChange={handleInputChange}
                        />
                        <span className="overLabel">
                          My most useless skill is
                        </span>
                      </div>
                    </div>
                    <div className="boxWrapper">
                      <div className="squareIconBoxes">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-movie"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                          <path d="M8 4l0 16" />
                          <path d="M16 4l0 16" />
                          <path d="M4 8l4 0" />
                          <path d="M4 16l4 0" />
                          <path d="M4 12l16 0" />
                          <path d="M16 8l4 0" />
                          <path d="M16 16l4 0" />
                        </svg>
                      </div>

                      <div className="relative">
                        <span className="overLabel">My favorite movie is</span>
                        <input
                          type="text"
                          name="favoriteMovie"
                          value={formData.favoriteMovie}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="boxWrapper">
                      <div className="squareIconBoxes">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-device-tv-old"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                          <path d="M16 3l-4 4l-4 -4" />
                          <path d="M15 7v13" />
                          <path d="M18 15v.01" />
                          <path d="M18 12v.01" />
                        </svg>
                      </div>

                      <div className="relative">
                        <span className="overLabel">
                          My favorite TV show is
                        </span>
                        <input
                          type="text"
                          name="favoriteTvShow"
                          value={formData.favoriteTvShow}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="boxWrapper">
                      <div className="squareIconBoxes">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-dog"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M11 5h2" />
                          <path d="M19 12c-.667 5.333 -2.333 8 -5 8h-4c-2.667 0 -4.333 -2.667 -5 -8" />
                          <path d="M11 16c0 .667 .333 1 1 1s1 -.333 1 -1h-2z" />
                          <path d="M12 18v2" />
                          <path d="M10 11v.01" />
                          <path d="M14 11v.01" />
                          <path d="M5 4l6 .97l-6.238 6.688a1.021 1.021 0 0 1 -1.41 .111a.953 .953 0 0 1 -.327 -.954l1.975 -6.815z" />
                          <path d="M19 4l-6 .97l6.238 6.688c.358 .408 .989 .458 1.41 .111a.953 .953 0 0 0 .327 -.954l-1.975 -6.815z" />
                        </svg>
                      </div>

                      <div className="relative">
                        <span className="overLabel">My pet name is</span>
                        <input
                          type="text"
                          name="petName"
                          value={formData.petName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="userinputValues">
                    <div className="boxWrapper">
                      <div className="squareIconBoxes">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-wand"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M6 21l15 -15l-3 -3l-15 15l3 3" />
                          <path d="M15 6l3 3" />
                          <path d="M9 3a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" />
                          <path d="M19 13a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" />
                        </svg>
                      </div>

                      <p>My most useless skill is {formData.uselessSkill}</p>
                    </div>
                    <div className="boxWrapper">
                      <div className="squareIconBoxes">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-movie"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                          <path d="M8 4l0 16" />
                          <path d="M16 4l0 16" />
                          <path d="M4 8l4 0" />
                          <path d="M4 16l4 0" />
                          <path d="M4 12l16 0" />
                          <path d="M16 8l4 0" />
                          <path d="M16 16l4 0" />
                        </svg>
                      </div>
                      <p>My favourite movie is {formData.favoriteMovie} </p>
                    </div>
                    <div className="boxWrapper">
                      <div className="squareIconBoxes">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-device-tv-old"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                          <path d="M16 3l-4 4l-4 -4" />
                          <path d="M15 7v13" />
                          <path d="M18 15v.01" />
                          <path d="M18 12v.01" />
                        </svg>
                      </div>
                      <p>My favourite TV show is {formData.favoriteTvShow}</p>
                    </div>
                    <div className="boxWrapper">
                      <div className="squareIconBoxes">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-dog"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M11 5h2" />
                          <path d="M19 12c-.667 5.333 -2.333 8 -5 8h-4c-2.667 0 -4.333 -2.667 -5 -8" />
                          <path d="M11 16c0 .667 .333 1 1 1s1 -.333 1 -1h-2z" />
                          <path d="M12 18v2" />
                          <path d="M10 11v.01" />
                          <path d="M14 11v.01" />
                          <path d="M5 4l6 .97l-6.238 6.688a1.021 1.021 0 0 1 -1.41 .111a.953 .953 0 0 1 -.327 -.954l1.975 -6.815z" />
                          <path d="M19 4l-6 .97l6.238 6.688c.358 .408 .989 .458 1.41 .111a.953 .953 0 0 0 .327 -.954l-1.975 -6.815z" />
                        </svg>
                      </div>
                      <p>My pet name is {formData.petName}</p>
                    </div>
                  </div>
                )}
              </div>

              <Mobile>
                {edit && (
                  <div className="col">
                    <button
                      onClick={toggleEdit}
                      className="outline"
                      style={{ width: "100%" }}
                    >
                      {"Save"}
                    </button>
                  </div>
                )}
              </Mobile>
            </div>
          </div>

          <div>
            <div className="sameMarbtm">
              <h2 className="profileTitsCom">Interests</h2>
            </div>
            <div className="selectedIntCont">
              {selectedInterests?.map((id, index) => (
                <span className="fakeBadge" key={index}>
                  {
                    interestsList?.find(
                      (interest) => interest?.interestId === id
                    )?.interestName
                  }
                </span>
              ))}
              <button className="dashedOutline" onClick={handleModalToggle}>
                Add / Remove Interests
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        {hostReviewData?.totalCount > 0 && (
          <div className="ratingsCont">
            <h2 className="profileTitsCom">
              What guests say about {profileData?.fullName}
            </h2>

            <div className="ratingCardsCont">
              {hostReviewData?.reviews?.map((review) => {
                return (
                  <div className="ratingCard">
                    <div className="profileratings">
                      <div className="profImg">
                        <img src={review?.profileImage} alt="" />
                      </div>
                      <div className="profNameNStar">
                        <h4>{review?.userName}</h4>
                        <div className="starsCont">
                          {showStarRating(review?.rating)}
                        </div>
                      </div>
                    </div>

                    <div className="ratingText">
                      <p>{review?.comment}</p>
                    </div>
                  </div>
                );
              })}

              {showMore && (
                <div className="d-flex justify-content-center">
                  <b
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={loadMoreReview}
                  >
                    View More
                  </b>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {!authState.isHost && (
      <>
        <div className="partiesTimelineCont">
          <div className="partytimeLine_head">
            <h2 className="upperTit">Upcoming Parties</h2>

            {partiesLoading ? ( // Show loading indicator
              <p>Loading...</p>
            ) : userParties?.upcomingEvents?.length !== 0 ? (
              <div className="timeLineDottedCont">
                <div className="partyDateCont">
                  <div className="partydateHead"></div>
                  {userParties.upcomingEvents.slice(0, displayCountIncoming).map((party) => showPartyList(party))}

                  {userParties.upcomingEvents.length > displayCountIncoming && (
                    <button className="see-all-button" onClick={handleShowMoreUpcoming}>
                      Show More
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <div className="noPat">
                <img src={noParty} alt="" />
                <h2>Your Party Calendar is Empty</h2>
                <p>
                  Looks like you haven’t joined any parties yet. Don’t worry,
                  the fun is just a few clicks away!
                </p>
                <button className="see-all-button" onClick={redirectToParties}>
                  Explore Parties
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-up-right"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M17 7l-10 10" />
                    <path d="M8 7l9 0l0 9" />
                  </svg>
                </button>
              </div>
            )}
          </div>
        </div>
       
        {userParties?.pastEvents?.length !== 0 && (
          <div className="partiesTimelineCont">
            <div className="partytimeLine_head">
              <h2 className="upperTit">Past Parties</h2>
              <div className="timeLineDottedCont">
                <div className="partyDateCont">
                  <div className="partydateHead"></div>
                  {userParties.pastEvents.slice(0, displayCountPast).map((party) => showPartyList(party))}

                  {userParties.pastEvents.length > displayCountPast && (
                    <button className="see-all-button" onClick={handleShowMorePast}>
                      Show More
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )}

      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleFileUpload}
        style={{ display: "none" }}
      />
    </>
  );
}

export default Dashboard;
