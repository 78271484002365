import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { brandLogos } from "../../../assets/brand-logos";
import SwiperCore, { Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

import Slider from "react-slick";

import { Desktop, Mobile } from "../../helpers/Breakpoints";

import bg1 from "../../../assets/desktopbrandImg/HOEGARDEN.jpeg";
// import bg2 from "../../../assets/Copy of DSC_0358.JPG";
import bg2 from "../../../assets/desktopbrandImg/IN A CAN.jpeg";
import bg3 from "../../../assets/desktopbrandImg/Mr. jerry_s.jpeg";
import bg4 from "../../../assets/desktopbrandImg/OLIO (2).jpeg";
import bg5 from "../../../assets/desktopbrandImg/OLIO.jpeg";
import bg6 from "../../../assets/desktopbrandImg/STOK (2).jpeg";
import bg7 from "../../../assets/desktopbrandImg/STOK (3).jpeg";
import bg8 from "../../../assets/desktopbrandImg/STOK.jpeg";
import bg9 from "../../../assets/desktopbrandImg/budweiser.jpeg";
import bg10 from "../../../assets/desktopbrandImg/dewars (2).jpeg";
import bg11 from "../../../assets/desktopbrandImg/dewars.jpeg";
import bg12 from "../../../assets/desktopbrandImg/ganna.jpeg";
import bg13 from "../../../assets/desktopbrandImg/highbury.jpeg";
import bg14 from "../../../assets/desktopbrandImg/wendys 2.jpeg";
import bg15 from "../../../assets/desktopbrandImg/wendys.jpeg";
import bg16 from "../../../assets/desktopbrandImg/white owl (2).jpeg";
import bg17 from "../../../assets/desktopbrandImg/white owl.jpeg";

export default function Brands() {
  const MemoizedBrandsSlideDesktop = useCallback(() => {
    return BrandsSlideDesktop();
  }, []);

  const [imageOpacity, setImageOpacity] = useState(0);
  const [currentImage, setCurrentImage] = useState(0);

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: window.innerWidth < 500 ? false : true,
    pauseOnHover: true,
    // initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // useEffect(() => {
  //   console.log("currentImage", currentImage, imageOpacity);
  //   imageFadeIn();

  //   const timeout1 = setTimeout(() => {
  //     console.log("setTimeout");
  //     switchImage()
  //   }, 6000);

  //   return () => clearTimeout(timeout1);
  // }, [currentImage]);

  const imageFadeOut = () => {
    if (imageOpacity > 0) {
      setImageOpacity(imageOpacity - 0.01);
      setTimeout(() => {
        imageFadeOut();
      }, 500);
    }
  };

  const imageFadeIn = () => {
    if (imageOpacity < 1) {
      setImageOpacity(imageOpacity + 0.01);
      setTimeout(() => {
        imageFadeIn();
      }, 500);
    }
  };

  const switchImage = () => {
    imageFadeOut();
    setTimeout(() => {
      setCurrentImage(currentImage < images.length - 1 ? currentImage + 1 : 0);
      setImageOpacity(0);
      setTimeout(() => {
        imageFadeIn();
      }, 100);
    }, 500);
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const images = [
    bg1,
    bg2,
    bg3,
    bg4,
    bg5,
    bg6,
    bg7,
    bg8,
    bg9,
    bg10,
    bg11,
    bg12,
    bg13,
    bg14,
    bg15,
    bg16,
    bg17,
  ];
  const mobImages = [
    bg1,
    bg2,
    bg3,
    bg4,
    bg5,
    bg6,
    bg7,
    bg8,
    bg9,
    bg10,
    bg11,
    bg12,
    bg13,
    bg14,
    bg15,
    bg16,
    bg17,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 10);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="wholegbg_black brandsCar_cont">
      {/* <img src={images[currentImage]} style={{
        background: 'red', flex: 1, height: '100%',
        widows: '100%', transition: 'ease-in', opacity: imageOpacity
      }}></img> */}

      <h2 className="titleStack">Trusted by Popular Brands</h2>

      <div className="bgVideoLoop_stack">
        <div className="chaningImages">
          <Desktop>
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Slide ${index}`}
                className={`leftImage ${activeIndex === index ? "active" : ""}`}
              />
            ))}
          </Desktop>
          <Mobile>
            {mobImages.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Slide ${index}`}
                className={`leftImage ${activeIndex === index ? "active" : ""}`}
              />
            ))}
          </Mobile>
        </div>

        <Slider {...settings}>
          {brandLogos.map((logo) => {
            return (
              <div className="logoContBrands">
                <img src={logo} />
              </div>
            );
          })}
        </Slider>

        {/* <MemoizedBrandsSlideDesktop /> */}
      </div>
    </section>
  );
}

function BrandsSlideDesktop() {
  return (
    <Swiper autoplay spaceBetween={0} slidesPerView={6}>
      {brandLogos.map((logo) => {
        return (
          <SwiperSlide>
            <img
              src={logo}
              className="img-fluid"
              alt="bacardi"
              height="50%"
              //width="220"
              width="50%"
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
