import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";

const s3Client = new S3Client({
    endpoint: process.env.REACT_APP_S3_BUCKET_URL,
    region: process.env.REACT_APP_S3_REGION,
    credentials: {
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
    },
    forcePathStyle: true,
});

export function handleFileUploadS3(event, folder) {
    return new Promise(async (resolve, reject) => {
        if (event && event.length > 0) {
            const file = event[0];
            const uniqueFileName = `${Date.now()}-${file.name}`;
            const folderPath = `playace/${folder}/`;
            const objectKey = `${folderPath}${uniqueFileName}`;
            const contentType = file.type;

            const params = {
                Bucket: process.env.REACT_APP_S3_BUCKET,
                Key: objectKey,
                Body: file,
                ACL: 'public-read',
                ContentType: contentType,
            };

            try {
                const command = new PutObjectCommand(params);
                await s3Client.send(command);
                
                const fileUrl = `${process.env.REACT_APP_S3_BUCKET_URL}/${process.env.REACT_APP_S3_BUCKET}/${objectKey}`;
                resolve(fileUrl);
            } catch (error) {
                console.error("Upload error:", error);
                resolve(null);
            }
        } else {
            resolve(null);
        }
    });
}
