import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Container, Row } from "react-bootstrap";
import ScrollContainer from "react-indiana-drag-scroll";
import { Link } from "react-router-dom";
import { getAllEventsByDateAndLocation } from "../../../api/requests";
import { EventCard } from "../../modules/content";
import { useSelector } from "react-redux";
import { Skeleton } from "../../modules/common";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Slider from "react-slick";

import noParty from "../../../assets/image 76.png";
import { Desktop, Mobile } from "../../helpers/Breakpoints";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function Attend(props) {
  let [slideShow, setSlideShow] = useState(null);

  var settings = {
    dots: false,
    arrows: true,
    infinite: slideShow > 3 ? true : false,
    speed: 500,
    slidesToShow: slideShow,
    slidesToScroll: 1,
    autoplay: window.innerWidth < 500 ? false : true,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow className="customArrow" />,
    prevArrow: <SamplePrevArrow className="customArrow" />,
    // initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let currentEvent = props?.currentEvent?.data?.eventId;
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const [userData, setUserData] = useState();
  const rand = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const stateAuth = useSelector((state) => state.auth);
  let userObj = useSelector((state) => state.auth.data);
  userObj = isAuthenticated ? JSON.parse(userObj) : [];

  const location = useLocation();
  const history = useHistory();

  const [events, setEvents] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const redirectToParties = () => {
    const urlParams = new URLSearchParams(location.search);
    const city = urlParams.get("city");
    if (!city) {
      console.log("city not found");
      return;
    }
    urlParams.set("page", "1");
    urlParams.set("filter", "all");
    if (events && events?.length === 0) {
      urlParams.set("city", "0");
    }
    history.push({ search: urlParams.toString(), pathname: "/parties" });
  };

  useEffect(() => {
    callEventListing();
  }, [location.search]);

  const callEventListing = async () => {
    const urlParams = new URLSearchParams(location.search);
    const city = urlParams.get("city");

    setIsLoading(true);
    let request = {
      fromDate: "",
      toDate: "",
      page: 1,
      limit: 4,
      cities: [city ? parseInt(city) : stateAuth?.selectedCity?.id],
      isLanding: true,
    };

    let response = await getAllEventsByDateAndLocation(request);

    if (response?.data) {
      console.log(".....", response?.data?.length);
      setIsLoading(false);
      setEvents(response?.data);

      if (response?.data?.length === 1) {
        setSlideShow(1);
      }
      if (response?.data?.length === 2) {
        setSlideShow(2);
      }
      if (response?.data?.length >= 3) {
        setSlideShow(3);
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // if (events && events?.length === 0 && !isLoading) {
  //   return <></>;
  // }
  return (
    <section className="attend-section mTop specialTop arrrowsCont">
      {props.title ? (
        <>
          <h2 className="mb-5">{props.title}</h2>

          {/* <ScrollContainer
            // onClick={scrollToTop}
            className="scroll-container"
            style={{ padding: "0 3.2rem 1.2rem 0.2rem" }}
            nativeMobileScroll={true}
            horizontal={true}
          > */}

          <Desktop>
            {events?.length < 4 ? (
              <ScrollContainer
                // onClick={scrollToTop}
                className="scroll-container"
                style={{ padding: "0 3.2rem 1.2rem 0.2rem" }}
                nativeMobileScroll={true}
                horizontal={true}
              >
                <div className="d-flex">
                  {isLoading && events === undefined
                    ? rand?.map((item, index) => {
                        return <Skeleton key={index} />;
                      })
                    : events?.map((item, index) => {
                        const eventDate = new Date(item.eventDate);
                        if (
                          item?.status == "live" &&
                          eventDate > yesterday &&
                          item?.eventId !== currentEvent
                        ) {
                          return (
                            <EventCard
                              classes="maxfitCard"
                              key={index}
                              // type="party"
                              {...item}
                              userData={userData}
                              handleFavoriteChange={callEventListing}
                            />
                          );
                        } else {
                          return null;
                        }
                      })}
                </div>
              </ScrollContainer>
            ) : (
              <Slider {...settings}>
                {isLoading && events === undefined
                  ? rand?.map((item, index) => {
                      return <Skeleton key={index} />;
                    })
                  : events?.map((item, index) => {
                      const eventDate = new Date(item.eventDate);
                      if (
                        item?.status == "live" &&
                        eventDate > yesterday &&
                        item?.eventId !== currentEvent
                      ) {
                        return (
                          <EventCard
                            classes="maxfitCard"
                            key={index}
                            // type="party"
                            {...item}
                            userData={userData}
                            handleFavoriteChange={callEventListing}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
              </Slider>
            )}
          </Desktop>

          <Mobile>
            <Slider {...settings}>
              {isLoading && events === undefined
                ? rand?.map((item, index) => {
                    return <Skeleton key={index} />;
                  })
                : events?.map((item, index) => {
                    const eventDate = new Date(item.eventDate);
                    if (
                      item?.status == "live" &&
                      eventDate > yesterday &&
                      item?.eventId !== currentEvent
                    ) {
                      return (
                        <EventCard
                          classes="maxfitCard"
                          key={index}
                          // type="party"
                          {...item}
                          userData={userData}
                          handleFavoriteChange={callEventListing}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
            </Slider>
          </Mobile>

          {/* </ScrollContainer> */}
          {props.button ? (
            <div className="text-center mt-4 mb-5">
              <Link
                to="/parties"
                className="btn btn-reg btn-outline-primary fw-bold btn-padding"
              >
                Explore more parties
              </Link>
            </div>
          ) : null}
        </>
      ) : (
        <>
          <Container>
            <h2 className="partiesHead">
              {stateAuth?.selectedCity?.city === "All"
                ? "All Parties"
                : `Parties in ${stateAuth?.selectedCity?.city}`}
            </h2>

            {events && events?.length === 0 && !isLoading ? (
              <div className="noPartiesFound">
                <div className="nopartyFallBack">
                  <img src={noParty} alt="" />
                  <h3>
                    It looks like there are no parties happening in{" "}
                    {stateAuth?.selectedCity?.city} at the moment.
                  </h3>
                  <p>Check out nearby locations for more excitement!</p>
                </div>
              </div>
            ) : (
              <div className="gridParty">
                {isLoading && events === undefined
                  ? rand.map((item, index) => {
                      return <Skeleton key={index} />;
                    })
                  : events?.map((item, index) => {
                      const eventDate = new Date(item.eventDate);
                      if (item.status == "live" && eventDate > yesterday) {
                        return (
                          <EventCard
                            classes=""
                            key={index}
                            // type="party"
                            isSection="attend"
                            {...item}
                            userData={userData}
                            handleFavoriteChange={callEventListing}
                          />
                        );
                      }
                      return null;
                    })}
              </div>
            )}
          </Container>
          {props.button ? (
            <div className="text-center mt-4 mb-5" onClick={redirectToParties}>
              <div className="btn btn-reg btn-padding outlineGray">
                See all Parties
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-up-right"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M17 7l-10 10" />
                  <path d="M8 7l9 0l0 9" />
                </svg>
              </div>
            </div>
          ) : null}
          {/* <Container>
            <h2>House parties to attend near you</h2>
            <p className="mb-5 text-muted font-weight-normal">
              Experience a new and comfortable way of socializing.
            </p>
            <ScrollContainer
              onClick={scrollToTop}
              className="scroll-container"
              style={{ padding: "0 1.2rem 1.2rem 0.2rem" }}
            >
              <Row className="attend-no-wrap">
                {isLoading && data === undefined
                  ? rand.map((item, index) => {
                      return <Skeleton key={index} />;
                    })
                  : data?.data?.map((item, index) => {
                      const eventDate = new Date(item.eventDate);
                      if (item.status == "live" && eventDate > yesterday) {
                        return (
                          <EventCard
                            classes="col-md-4 col-sm-10 col-12 mx-2"
                            key={index}
                            type="party"
                            isSection="attend"
                            {...item}
                            userData={userData}
                          />
                        );
                      }
                      return null;
                    })}
              </Row>
            </ScrollContainer>
          </Container>
          {props.button ? (
            <div className="text-center mt-4 mb-5">
              <Link
                to="/parties/page/1"
                className="btn btn-reg btn-outline-primary fw-bold btn-padding"
              >
                Explore more parties
              </Link>
            </div>
          ) : null} */}
        </>
      )}
    </section>
  );
}

export default Attend;
